import { useState, type FormEvent } from "react"
import { useTranslation } from "react-i18next"
import { useStore } from "@nanostores/react"
import captureException from "@utils/captureException"

import { $auth } from "@stores/auth"
import { $favorites, mutateFavorites, setTargetFavoriteGroupId } from "@stores/favorites"
import { $favoritesDialog, openUserListChangeDialog, type AddToDifferentListState } from "@stores/favoritesDialog"
import apiRequest from "@utils/apiRequest"
import useToast from "@hooks/useToast"

import { FavoriteStep, type FavoriteEntityType } from "@types"
import BottomSheet from "@components/BottomSheet"
import TextInput from "@components/TextInput"
import Button from "@components/Button"
import FavoritesListItem from "./FavoritesListItem"

interface FavoritesDialogProps {
  "data-cy"?: string
}

const FavoritesDialog = (props: FavoritesDialogProps): JSX.Element => {
  const { showToast } = useToast()
  const { t } = useTranslation<string>("common")

  const { user } = useStore($auth)
  const { favoriteGroups } = useStore($favorites)
  const favoriteGroupsParsed: Domain.HappyPlates.DataTransferObjects.FavoriteGroupData[] = JSON.parse(
    favoriteGroups ?? "[]"
  )
  const { state } = useStore($favoritesDialog)
  const setState = (state: AddToDifferentListState) => $favoritesDialog.setKey("state", state)
  const { targetFavoriteGroupId } = useStore($favorites)

  const [listName, setListName] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const invalidateCache = () => {
    mutateFavorites()
  }

  const handleSaveToDifferentList = async (favoriteId: number) => {
    setIsLoading(true)
    await apiRequest(`/v1/favorites/${favoriteId}`, {
      body: { favoriteGroupId: targetFavoriteGroupId },
      method: "PUT",
      token: user?.token,
    })
    setIsLoading(false)
    setState({ step: FavoriteStep.CLOSED })
    invalidateCache()
    setTargetFavoriteGroupId(null)
  }

  const handleUpdateList = async () => {
    setIsLoading(true)

    await apiRequest(`/v1/favorite-groups/${targetFavoriteGroupId}`, {
      body: { name: listName },
      method: "PUT",
      token: user?.token,
    })
    showToast(t("favorites.listRenamedToast"))
    setState({ step: FavoriteStep.CLOSED })
    setIsLoading(false)
    setTargetFavoriteGroupId(null)
    invalidateCache()
    location.reload()
  }

  const handleDeleteList = async () => {
    setIsLoading(true)
    if (targetFavoriteGroupId) {
      try {
        await apiRequest(`/v1/favorite-groups/${targetFavoriteGroupId}`, { method: "DELETE", token: user?.token })
        showToast(t("favorites.deleteListToast"))
        setState({ step: FavoriteStep.CLOSED })
        setTargetFavoriteGroupId(null)
        invalidateCache()
      } catch (error: any) {
        captureException(error)
        setIsLoading(false)
      }
      setIsLoading(false)
      location.href = "/favorites"
    }
  }

  const handleCreateList = async () => {
    setIsLoading(true)
    await apiRequest("/v1/favorite-groups", { body: { name: listName }, method: "POST", token: user?.token })
    setIsLoading(false)
    showToast(t("favorites.addListToast"))
    setState({ step: FavoriteStep.CLOSED })
    setTargetFavoriteGroupId(null)
    invalidateCache()
  }

  const handleAddListAndAddEntity = async (favoriteId: number) => {
    setIsLoading(true)
    const response = await apiRequest("/v1/favorite-groups", {
      body: { name: listName },
      method: "POST",
      token: user?.token,
    })

    const newListId = response?.data?.id

    await apiRequest(`/v1/favorites/${favoriteId}`, {
      body: { favoriteGroupId: newListId },
      method: "PUT",
      token: user?.token,
    })
    setIsLoading(false)
    showToast(t("favorites.recipeMovedToDifferentListToast"))
    setTargetFavoriteGroupId(null)
    invalidateCache()
    setState({ step: FavoriteStep.CLOSED })
  }

  const onConfirm = () => {
    if (state.step === FavoriteStep.SELECT_LIST) {
      handleSaveToDifferentList(state.favoriteId)
    } else if (state.step === FavoriteStep.CREATE_LIST) {
      handleCreateList()
    } else if (state.step === FavoriteStep.CREATE_LIST_AND_ADD_ENTITY) {
      handleAddListAndAddEntity(state.favoriteId)
    } else if (state.step === FavoriteStep.UPDATE_LIST) {
      handleUpdateList()
    } else if (state.step === FavoriteStep.DELETE_LIST) {
      handleDeleteList()
    }
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    onConfirm()
  }

  const onCancel = () => {
    setState({ step: FavoriteStep.CLOSED })
  }

  const onSetListName = (listName: string) => {
    setListName(listName)
  }

  const onCreateListAndAddEntity = (entityId: number, entityType: FavoriteEntityType, favoriteId: number) => {
    setState({ step: FavoriteStep.CREATE_LIST_AND_ADD_ENTITY, entityId, entityType, favoriteId })
  }

  const onSetTargetFavoriteGroupId = (listId: number | null) => {
    setTargetFavoriteGroupId(listId)
  }

  return (
    <BottomSheet
      is_open={state.step !== FavoriteStep.CLOSED}
      onClose={onCancel}
      disableBodyScrolling={true}
      id="add-to-different-list-dialog"
    >
      <div
        className={`md:w-full  md:min-w-[45rem] ${
          state.step === FavoriteStep.DELETE_LIST ? "md:max-w-[54rem]" : "md:max-w-[80rem]"
        }`}
        data-cy="user-list-dialog"
      >
        {state.step === FavoriteStep.SELECT_LIST && (
          <form onSubmit={handleSubmit} data-cy="user-list-dialog-select-list">
            <div className="px-24 pt-40 pb-24 max-h-[45rem] overflow-y-auto">
              <h1 className="text-24 font-semibold mb-24 whitespace-pre-wrap">{t("favorites.addToList")}</h1>
              <ul data-cy={props["data-cy"]}>
                <li className="mb-16">
                  <Button
                    onClick={() => {
                      // check state again or ts does not know the current state values
                      if (state.step === FavoriteStep.SELECT_LIST) {
                        onCreateListAndAddEntity(state.entityId, state.entityType, state.favoriteId)
                      }
                    }}
                    version="ADD"
                    size="LARGE"
                    is_block
                    data-cy="user-list-dialog-btn-new-list"
                  >
                    {t("favorites.createNewList")}
                  </Button>
                </li>
                {favoriteGroupsParsed.map((group, index) => {
                  return (
                    <li className="mb-16" key={index}>
                      <FavoritesListItem
                        list_id={group.id}
                        list_name={group.name}
                        recipe_count={group.favorites_count || 0}
                        favorites={group.favorites}
                        selected={String(group.id) === targetFavoriteGroupId}
                        setTargetListId={() => onSetTargetFavoriteGroupId(group.id)}
                      />
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="border-t border-neutral-200 px-24 py-20 flex justify-between md:justify-end">
              <Button
                version="SECONDARY"
                size="MEDIUM"
                onClick={() => onCancel()}
                is_disabled={isLoading}
                data-cy="user-list-dialog-cancel"
                class_name="mr-8 md:w-auto"
                is_block
              >
                {t("common.cancel")}
              </Button>
              <Button
                version="SPINACH"
                size="MEDIUM"
                type="submit"
                is_loading={isLoading}
                data-cy="user-list-dialog-confirm"
                class_name="md:w-auto"
                is_block
              >
                {t("common.save")}
              </Button>
            </div>
          </form>
        )}
        {state.step === FavoriteStep.CREATE_LIST_AND_ADD_ENTITY && (
          <form onSubmit={handleSubmit} data-cy="user-list-dialog-create-list-and-add">
            <div className="px-24 pt-40 pb-24">
              <div className="flex items-center mb-28">
                <Button
                  version="BACK"
                  size="SMALL_SQUARE"
                  onClick={() => {
                    if (state.step === FavoriteStep.CREATE_LIST_AND_ADD_ENTITY) {
                      openUserListChangeDialog(state.entityId, state.entityType, state.favoriteId)
                    }
                  }}
                  is_disabled={isLoading}
                  data-cy="step-back"
                  class_name="mr-20"
                />
                <h1 className="text-24 font-semibold whitespace-pre-wrap">{t("favorites.createNewList")}</h1>
              </div>
              <div className="flex" data-cy={props["data-cy"]}>
                <div className="w-full">
                  <TextInput
                    required
                    label={t("favorites.listNamePlaceholder")}
                    value={listName}
                    onChange={onSetListName}
                    version="COMPACT"
                    data-cy="add-list-dialog-list-name"
                  />
                </div>
              </div>
            </div>
            <div className="px-24 pb-20 flex justify-between md:justify-end">
              <Button
                version="SECONDARY"
                size="MEDIUM"
                onClick={() => onCancel()}
                is_disabled={isLoading}
                data-cy="user-list-dialog-cancel"
                class_name="mr-8 md:w-auto"
                is_block
              >
                {t("common.cancel")}
              </Button>
              <Button
                version="SPINACH"
                size="MEDIUM"
                type="submit"
                is_loading={isLoading}
                data-cy="user-list-dialog-confirm"
                class_name="md:w-auto"
                is_block
              >
                {t("common.save")}
              </Button>
            </div>
          </form>
        )}

        {state.step === FavoriteStep.CREATE_LIST && (
          <form onSubmit={handleSubmit} data-cy="user-list-dialog-create-list">
            <div className="px-24 pt-40 pb-24">
              <div className="flex items-center mb-28">
                <h1 className="text-24 font-semibold whitespace-pre-wrap">{t("favorites.createNewList")}</h1>
              </div>
              <div className="flex" data-cy={props["data-cy"]}>
                <div className="w-full">
                  <TextInput
                    required
                    label={t("favorites.listNamePlaceholder")}
                    value={listName}
                    onChange={onSetListName}
                    version="COMPACT"
                    data-cy="add-list-dialog-list-name"
                  />
                </div>
              </div>
            </div>
            <div className="px-24 pb-20 flex justify-between md:justify-end">
              <Button
                version="SECONDARY"
                size="MEDIUM"
                onClick={() => onCancel()}
                is_disabled={isLoading}
                data-cy="user-list-dialog-cancel"
                class_name="mr-8 md:w-auto"
                is_block
              >
                {t("common.cancel")}
              </Button>
              <Button
                version="SPINACH"
                size="MEDIUM"
                type="submit"
                is_loading={isLoading}
                data-cy="user-list-dialog-confirm"
                class_name="md:w-auto"
                is_block
              >
                {t("common.save")}
              </Button>
            </div>
          </form>
        )}
        {state.step === FavoriteStep.UPDATE_LIST && (
          <form onSubmit={handleSubmit} data-cy="user-list-dialog-update-list">
            <div className="px-24 pt-40 pb-24 text-left">
              <div className="flex flex-col">
                <h1 className="text-24 font-semibold whitespace-pre-wrap mb-16">{t("favorites.renameList")}</h1>
              </div>
              <div className="flex" data-cy={props["data-cy"]}>
                <div className="w-full">
                  <TextInput
                    required
                    label={t("favorites.listNamePlaceholder")}
                    value={listName}
                    onChange={(value) => onSetListName(value)}
                    version="COMPACT"
                    data-cy="add-list-dialog-list-name"
                  />
                </div>
              </div>
            </div>
            <div className="px-24 pb-20 flex justify-between md:justify-end">
              <Button
                version="SECONDARY"
                size="MEDIUM"
                onClick={() => onCancel()}
                is_disabled={isLoading}
                data-cy="user-list-dialog-cancel"
                class_name="mr-8 md:w-auto"
                is_block
              >
                {t("common.cancel")}
              </Button>
              <Button
                version="SPINACH"
                size="MEDIUM"
                type="submit"
                is_loading={isLoading}
                data-cy="user-list-dialog-confirm"
                class_name="md:w-auto"
                is_block
              >
                {t("common.save")}
              </Button>
            </div>
          </form>
        )}
        {state.step === FavoriteStep.DELETE_LIST && (
          <form onSubmit={handleSubmit} data-cy="user-list-dialog-delete-list">
            <div className="px-24 pt-40 pb-24 text-left">
              <div className="flex flex-col">
                <h1 className="text-24 font-semibold whitespace-pre-wrap mb-16">{t("favorites.deleteListHeadline")}</h1>
                <p>{t("favorites.deleteListCopy")}</p>
              </div>
            </div>
            <div className="px-24 pb-20 flex justify-between md:justify-end">
              <Button
                version="SECONDARY"
                size="MEDIUM"
                onClick={() => onCancel()}
                is_disabled={isLoading}
                data-cy="user-list-dialog-cancel"
                class_name="mr-8 md:w-auto"
                is_block
              >
                {t("common.cancel")}
              </Button>
              <Button
                version="PRIMARY"
                size="MEDIUM"
                type="submit"
                is_loading={isLoading}
                data-cy="user-list-dialog-confirm"
                class_name="md:w-auto"
                is_block
              >
                {t("favorites.deleteListConfirm")}
              </Button>
            </div>
          </form>
        )}
      </div>
    </BottomSheet>
  )
}

export default FavoritesDialog
