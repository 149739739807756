import { useTranslation } from "react-i18next"

import { ReactComponent as IconCheck } from "@icons/check.svg"
import FavoritesListImage from "@components/FavoritesListImage"

interface FavoritesListItemProps {
  selected?: boolean
  list_id: number | null
  list_name?: string
  recipe_count: number
  favorites?: Domain.HappyPlates.DataTransferObjects.FavoriteData[]
  setTargetListId: (listId: number | null) => void
}

const FavoritesListItem = (props: FavoritesListItemProps): JSX.Element => {
  const { selected, list_name, recipe_count, list_id, favorites } = props
  const { t } = useTranslation<string>("common")

  return (
    <button
      className="border border-neutral-200 bg-white hover:border-neutral-300 h-64 px-24 text-base rounded-16 flex items-center align-items-center w-full justify-between"
      onClick={() => props.setTargetListId(list_id)}
      type="button"
      data-cy="user-recipe-list-item"
    >
      <div className="flex items-center">
        <div className="flex justify-between h-32 w-44 mr-12 relative rounded-4 overflow-hidden">
          <FavoritesListImage favorites={favorites} small />
        </div>
        <div className="flex flex-col text-left">
          <h3 className="text-14 leading-none mb-4">{list_name}</h3>
          <span className="inline-block text-14 leading-none text-neutral-300">
            {recipe_count} {recipe_count === 0 || recipe_count > 1 ? t("common.recipes") : t("common.recipe")}
          </span>
        </div>
      </div>
      <div>
        {selected && (
          <div className="h-16 w-16 bg-kiwi text-white rounded-full overflow-hidden flex items-center justify-center">
            <IconCheck className="w-6 h-6" />
          </div>
        )}
      </div>
    </button>
  )
}

export default FavoritesListItem
